import { Injectable } from '@angular/core';
import * as L from 'leaflet';
import * as Turf from '@turf/turf';

import { LatLng, tileLayer } from 'leaflet';
import { from, Observable, of } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { LocalStorageService } from '../../core/core.module';
import { Area } from '../models/area.model';
import { GpsLocation } from '../models/gps-location.model';
import * as TurfArea from '@turf/area';
import { MatTabGroup } from '@angular/material/tabs';
import { Dropzone } from '../models/dropzone.model';
import { Activity } from '../models/activity.model';
import { ActivityItem } from '../models/activity-item.model';

@Injectable()
export class MapUtilsService {
    constructor() {}

    public getDistanceBetweendPoints(lng1: number, lat1: number, lng2: number, lat2: number): number {
        const distance = Math.floor(Turf.distance(Turf.point([lng1, lat1]), Turf.point([lng2, lat2])) * 1000);
        return distance;
    }

    public getDropzonesUserNear(dropzones: Dropzone[], location: GpsLocation): Dropzone[] {

        if (!dropzones || dropzones.length == 0 || location == null) return [];

        let result: Dropzone[] = [];

        for (const dropzone of dropzones) {
            if (location.lat >0 && location.lng > 0 && dropzone.lat > 0 && dropzone.lng > 0) {
                const distance = this.getDistanceBetweendPoints(location.lng, location.lat, dropzone.lng, dropzone.lat);
                if (distance <= dropzone.activation_distance) result.push(dropzone);
            }
        }
        return result;
    }

    public preparePolygonCoordinates(coordinates: []): L.LatLng[] {
        return this.CoordinatesGpsToLeaflet(coordinates);
    }

    public centerOfArea(area: Area): L.LatLng {
        if (area.coordinates.length > 0) {
            const polygon = Turf.polygon(area.coordinates[0]);
            const center = Turf.centroid(polygon);
            return new L.LatLng(center.geometry.coordinates[0], center.geometry.coordinates[1]);
        }
        return null;
    }

    public distanceToArea(area: Area, location: GpsLocation): number {
        const polygon = Turf.polygon(area.coordinates[0]);
        const center = Turf.centroid(polygon);
        const distance = Math.floor(Turf.distance(Turf.point([location.lng, location.lat]), center) * 1000);
        return distance;
    }

    public sortAreasByDistance(areas: Area[], location: GpsLocation): Area[] {
        if (!areas || areas.length == 0 || location == null) return [];

        let copyOfAreas = [...areas];
        let result = copyOfAreas.sort((a, b) => (this.distanceToArea(a, location) < this.distanceToArea(b, location) ? -1 : 1));

        return result;
    }

    public getAreasUserIn(areas: Area[], location: GpsLocation): Area[] {
        if (!areas || areas.length == 0 || location == null) return [];

        let result: Area[] = [];
        for (const area of areas) {
            for (const poly of area.coordinates) {
                if (this.IsPointInPolygon(this.CoordinatesToGps(poly[0]), location.lat, location.lng)) {
                    result.push(area);
                    break;
                }
            }
        }

        return result;
    }

    public CoordinatesGpsToArray(input: GpsLocation[]): any[] {
        let result: any[] = [];

        for (const point of input) {
            result.push([Number(point.lng), Number(point.lat)]);
        }
        return result;
    }

    public CoordinatesGpsToLeaflet(input: GpsLocation[]): LatLng[] {
        let result: LatLng[] = [];

        for (const point of input) {
            result.push(new L.LatLng(Number(point.lat), Number(point.lng)));
        }
        return result;
    }

    public CoordinatesToGps(coords: Array<any>): GpsLocation[] {
        let coordinates: GpsLocation[] = [];

        if (coords.length <= 4) return [];
        for (const point of coords) {
            coordinates.push(new GpsLocation(Number(point[0]), Number(point[1]), 0, 0));
        }
        return coordinates;
    }

    public IsPointInPolygon(polygon: GpsLocation[], latitude: number, longitude: number): boolean {
        let isInIntersection: boolean = false;
        let actualPointIndex = 0;
        let pointIndexBeforeActual = polygon.length - 1;
        var offset = this.calculateLonOffsetFromDateLine(polygon);
        longitude = longitude < 0.0 ? longitude + offset : longitude;
        for (let actualPointPosition of polygon) {
            var p1Lat = actualPointPosition.lat;
            var p1Lon = actualPointPosition.lng;

            var p0Lat = polygon[pointIndexBeforeActual].lat;
            var p0Lon = polygon[pointIndexBeforeActual].lng;

            if (p1Lon < 0.0) p1Lon += offset;
            if (p0Lon < 0.0) p0Lon += offset;

            if (this.isPointLatitudeBetweenPolyLine(p0Lat, p1Lat, latitude) && this.isPointRightFromPolyLine(p0Lat, p0Lon, p1Lat, p1Lon, latitude, longitude)) {
                isInIntersection = !isInIntersection;
            }

            pointIndexBeforeActual = actualPointIndex;
            actualPointIndex++;
        }

        return isInIntersection;
    }

    private calculateLonOffsetFromDateLine(polygon: GpsLocation[]): number {
        let offset = 0.0;
        var maxLonPoly = Math.max.apply(
            Math,
            polygon.map(function (o) {
                return o.lng;
            })
        );
        var minLonPoly = Math.min.apply(
            Math,
            polygon.map(function (o) {
                return o.lng;
            })
        );

        if (Math.abs(minLonPoly - maxLonPoly) > 180) {
            offset = 360.0;
        }
        return offset;
    }
    private isPointLatitudeBetweenPolyLine(polyLinePoint1Lat: number, polyLinePoint2Lat: number, poiLat: number): boolean {
        return (polyLinePoint2Lat <= poiLat && poiLat < polyLinePoint1Lat) || (polyLinePoint1Lat <= poiLat && poiLat < polyLinePoint2Lat);
    }
    private isPointRightFromPolyLine(
        polyLinePoint1Lat: number,
        polyLinePoint1Lon: number,
        polyLinePoint2Lat: number,
        polyLinePoint2Lon: number,
        poiLat: number,
        poiLon: number
    ): boolean {
        return poiLon < ((polyLinePoint1Lon - polyLinePoint2Lon) * (poiLat - polyLinePoint2Lat)) / (polyLinePoint1Lat - polyLinePoint2Lat) + polyLinePoint2Lon;
    }

    public surfaceFromPolygon(coordinates: any): string {
        var polygon = Turf.polygon(coordinates);
        var area = Turf.area(polygon);
        return Math.round(area / 1000000).toString();
    }

    public static GetDefaultMapOptions(): any {
        const options = {
            attributionControl: false,
            zoom: 5,
            useCache: true,
            zoomSnap: 0,
            crossOrigin: true,
            center: L.latLng(52, 29)
        };

        return options;
    }
    public distanceToAreaBorders(area: Area, location: GpsLocation): number {
        for (const poly of area.coordinates) {
            const coords = this.CoordinatesGpsToArray(this.CoordinatesToGps(poly[0]));

            const point = Turf.point([location.lng, location.lat]);

            const outerLine = Turf.lineString(coords);
            const distance = Turf.pointToLineDistance(point, outerLine);

            if (Turf.booleanPointInPolygon(point, Turf.polygon([coords]))) {
                return 0;
            }
            return distance * 1000;
        }
    }
}
